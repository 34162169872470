<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit' ? $t('general.edit') : $t('general.new')
      } ${$t('expert.rating')} ${expert_matching.expert_name} - ${
        expert_matching.title
      }`"
      :backButton="true"
      @backToPage="moveToOther('Expert Matching Dashboard')"
    >
      <template #form>
        <form>
          <div class="s-flex" style="align-items: flex-end">
            <Input
              type="number"
              v-model="edited_item.score"
              :label="$t('general.score')"
              style="max-width: 10%"
              :error="validation.score"
              :min="0"
              :max="10"
            />
            <b
              ><span class="s-ml-10"> {{ $t("expert.outOf") }} </span>
            </b>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click="cancelForm()">
              {{ $t("general.cancel") }}
            </Button>
            <Button
              class="s-ml-20"
              @click.prevent="onFinish"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import common from "@/mixins/common";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { ContentTemplate },
  props: ["inputType"],
  mixins: [form, common],
  data() {
    return {
      edited_item: {
        score: "",
      },
      validation: {
        score: "",
      },
      fileDoc: {},
      isLoading: false,
    };
  },

  mounted() {
    this.initData();
  },
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      expert_matching: "expertmatching/getExpertMatching",
    }),
  },
  methods: {
    ...mapActions({
      giveRating: "expert/giveRating",
      setExpertMatching: "expertmatching/setExpertMatchingSingleData",
    }),
    async initData() {
      this.setLoadingPage(true);
      await this.setExpertMatching(this.$route.params.id);
      this.setLoadingPage(false);
    },
    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        false,
        null,
        this.inputType
      );

      if (this.edited_item.score < 0 || this.edited_item.score > 10) {
        this.showSnackbar({
          type: "error",
          text: this.$t("expert.scoreMustBe"),
        });
      }

      if (!this.empty_field) {
        this.isLoading = true;
        const data = {
          id: this.$route.params.id,
          rating: this.edited_item.score,
        };
        await this.giveRating(data);
        if (this.$store.getters["expert/getStatus"] == 1) {
          this.$router.push(this.$translate({ name: "Expert Matching" }));
        } else {
          this.showSnackbar({
            type: "error",
            text: this.$t("expert.giveRatingFail"),
          });
        }
      }
      this.isLoading = false;
    },

    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },
    cancelForm() {
      this.$router.push(
        this.$translate({
          name: "Expert Matching",
        })
      );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
